import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================ https://lpcentre.com/api/
  // https://lpcentre.prokoders.space/api/
  // baseURL: 'https://lpcentre.prokoders.dev/api/',
  baseURL: process.env.VUE_APP_ROOT_API,

  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
