import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, BootstrapVueIcons,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Echo from 'laravel-echo'
import * as VueGoogleMaps from 'vue2-google-maps'
// import i18n from '@/libs/i18n'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueColorPicker from '@duoa/vue-color-picker'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// Because this components has its styles, you must also import the css file.
import '@duoa/vue-color-picker/dist/vue-color-picker.css'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// Axios Mock Adapter
// Axios Mock Adapter
// import '@/@fake-db/db'
// import '@/@fake-db/db'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueColorPicker)
// Vue.use(vueCountryRegionSelect)
Vue.use( CKEditor );
// window.Pusher = require('pusher-js')


// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: 123124,
//   cluster: 'mt1',
//   wsHost: 'prokoders.click',
//   wsPort: 6001,
//   enabledTransports: ['ws'],
//   forceTLS: false,
//   useTLS: false,
//   logToConsole: true,
// })

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC_tc1iaf5KIki9iiMPxPKhw9jDlDJVqq4',
    libraries: 'places',

  },
})
Vue.component('google-map', VueGoogleMaps.Map)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true
// const eventHub = new Vue() // Single event hub

//  // Distribute to components using global mixin
//  Vue.mixin({
//      data: function () {
//          return {
//              eventHub: eventHub
//          }
//      }
//  })
new Vue({
  router,
  store,

  render: h => h(App),
}).$mount('#app')
