import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { BSpinner,BModal } from 'bootstrap-vue'
import Editor from '@tinymce/tinymce-vue'




Vue.component(FeatherIcon.name, FeatherIcon,BSpinner,BModal,Editor)

