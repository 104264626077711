import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import lpcstore from './lpc-store'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import courseStoreModule from '@/views/courses/courseStoreModule'
import longcourseStoreModule from '@/views/longcourse/courseStoreModule'
import categoriesStoreModule from '@/views/categories/categoriesStoreModule'
import othercourseStoreModule from '@/views/othercourses/othercourseStoreModule'
import TrainingcourseStoreModule from '@/views/Trainingcourses/TrainingcourseStoreModule'
import instructorStoreModule from '@/views/Instructor/instructorStoreModule'
import priceStoreModule from '@/views/prices/priceStoreModule'
import citiesStoreModule from '@/views/citiesandlocation/citiesStoreModule'
import contentStoreModule from '@/views/content/contentStoreModule'
import schduleStoreModule from '@/views/Schedule/scheduleStoreModule'
import homepageStoreModule from '@/views/homepage/homepageStoreModule'
import seoStoreModule from '@/views/seo/seoStoreModule'
import homepageCourseLongStoreModule from '@/views/homepage-long-course/homepageStoreModule'
import accreditationsStoreModule from '@/views/accreditations/accreditationsStoreModule'
import ClientStoreModule from '@/views/clients/ClientStoreModule'
import slidesStoreModule from '@/views/slider/slidesStoreModule'
import galleryStoreModule from '@/views/gallery/galleryStoreModule'
import settingStoreModule from '@/views/setting/settingStoreModule'
import blogStoreModule from '@/views/blog/blogStoreModule'
import hCourseStoreModule from '@/views/hidden-course/hcourseStoreModule'
import careerStoreModule from '@/views/career/careerStoreModule'
import contactUsStoreModule from '@/views/contact-us/contactUsStoreModule'
import eventsStoreModule from '@/views/events/eventsStoreModule'
import organiserStoreModule from '@/views/organiser/organiserStoreModule'
import speakerStoreModule from '@/views/speaker/speakerStoreModule'
import LongschduleStoreModule from '@/views/LongCourseSchedule/scheduleStoreModule'
import longseoStoreModule from '@/views/LongSeo/seoStoreModule'
import longPriceModule from '@/views/LongPrice/priceStoreModule'

import ticketsStoreModule from '@/views/ticket/ticketsStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    courses: courseStoreModule,
    categories: categoriesStoreModule,
    othercourses: othercourseStoreModule,
    instructor: instructorStoreModule,
    cities: citiesStoreModule,
    price: priceStoreModule,
    content: contentStoreModule,
    schedule: schduleStoreModule,
    homepage: homepageStoreModule,
    seo: seoStoreModule,
    accreditations: accreditationsStoreModule,
    clients: ClientStoreModule,
    slides: slidesStoreModule,
    gallery: galleryStoreModule,
    setting: settingStoreModule,
    blog: blogStoreModule,
    contactUs: contactUsStoreModule,
    career: careerStoreModule,
    events: eventsStoreModule,
    speakers: speakerStoreModule,
    organisers: organiserStoreModule,
    longcourses: longcourseStoreModule,
    longschedule: LongschduleStoreModule,
    longPrice: longPriceModule,
    longSeo: longseoStoreModule,
    homepageCourseLong: homepageCourseLongStoreModule,
    trainingcourses: TrainingcourseStoreModule,
    hCourseStoreModule,
    tickets:ticketsStoreModule

  },
  strict: process.env.DEV,
})
